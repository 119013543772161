import type { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import * as Sentry from '@sentry/nextjs';

import Sanity from 'clients/Sanity';
import { AppSettings, SanityPage } from 'types';
import { Nav, Footer, Content, Meta, Popups } from 'components';

import { useNewsletterContext } from 'contexts/NewsletterContext';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useAppSettings } from 'contexts/AppSettingsContext';

const Page: NextPage<{ page: SanityPage; settings: AppSettings }> = ({ page, settings }) => {
  const router = useRouter();
  const newsletterContext = useNewsletterContext();
  const settingsContext = useAppSettings();

  useEffect(() => {
    if (!!settings && !!settingsContext && !settingsContext.settings) {
      settingsContext.setContext({ ...settingsContext, settings });
    }
  }, [settings, settingsContext]);

  if (!settings) return null;

  // TO-DO: Refactor logic to be more concise
  if (!!router.query?.entry) {
    if (router.query.entry !== newsletterContext.campaign) {
      newsletterContext.setContext({
        ...newsletterContext,
        campaign: router.query.entry as string
      });
    }
  }
  if (!!router.query?.campaign) {
    if (router.query.campaign !== newsletterContext.campaign) {
      newsletterContext.setContext({
        ...newsletterContext,
        campaign: router.query.campaign as string
      });
    }
  }

  return (
    <div className="Page">
      <Meta page={page} settings={settings} />
      <Nav menu={settings.navigation.menu} immersive={page.options?.immersiveNav} />
      <main>
        <Content blocks={page.content} />
        <Popups />
      </main>
      <Footer hide={page.options?.hideFooter} menu={settings.footer.menu} />
    </div>
  );
};

export const getStaticPaths: GetStaticPaths = async function () {
  const paths = [
    ...(await Sanity.pages.paths()),
    ...(await Sanity.posts.paths()),
    ...(await Sanity.events.paths()),
    ...(await Sanity.products.paths())
  ];

  return {
    paths,
    fallback: true
  };
};

export const getStaticProps: GetStaticProps = async function (context) {
  const slug = Array.isArray(context.params?.slug)
    ? context.params?.slug.join('/') || ''
    : context.params?.slug || '';

  const settings: AppSettings = await Sanity.settings.fetch();

  const redirect = settings.redirects?.permanentRedirects?.find(match => match.from === `/${slug}`);
  if (!!redirect) {
    if (typeof window !== 'undefined') {
      Sentry.withScope(scope => {
        scope.setExtra('redirect', redirect);
        Sentry.captureMessage('301: Known Permanent Redirect');
      });
    }
    return { redirect: { destination: redirect.to, permanent: true } };
  }

  // TO-DO: Create a better switch
  let page;
  // TO-DO: Consistent routing between products and posts
  if (slug.includes('products/')) page = await Sanity.products.get(slug.replace('products/', ''));
  if (slug.includes('blog/')) page = await Sanity.posts.fetch(slug);
  if (!page) page = await Sanity.pages.fetch(slug);
  if (!page) return { notFound: true };

  const isStaging = process.env.NEXT_PUBLIC_SANITY_DATASET === 'staging';
  if (process.env.NODE_ENV === 'production' && !isStaging && page.options?.stagingOnly) {
    return { notFound: true };
  }
  if (process.env.NODE_ENV === 'production' && page.options?.devOnly) return { notFound: true };

  return {
    props: { slug, page, settings },
    revalidate: 600
  };
};

export default Page;
